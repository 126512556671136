<template>
  <div style="padding: 15px">
    <v-card class="px-3 py-2">
      <v-row>
        <v-col
          cols="12"
          class="d-flex"
          style="
            position: relative;
            padding: 0 20px;
            text-align: center;
            margin-top: 20px;
          "
        >
          <h6 class="indigo--text" style="font-weight: bold; margin: auto">
            LIST HARGA
            <v-tooltip bottom color="grey">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  x-small
                  text
                  icon
                  color="grey darken-2"
                  @click="refreshPage"
                >
                  <v-icon small>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>refresh page</span>
            </v-tooltip>
          </h6>
        </v-col>
        <v-col cols="12">
          <div>
            <v-toolbar flat style="height: 60px; border-radius: 10px 10px 0 0">
              <!-- <router-link to="/expedition/makeorder"> -->

              <v-btn
                @click="addPriceList"
                outlined
                elevation="1"
                color="indigo"
                class="indigo--text font-weight-bold"
                style="font-size: 12px; margin-top: 5px"
                :disabled="loading"
              >
                Tambah Data
              </v-btn>
              <!-- </router-link> -->
              <div
                style="
                  width: 120px;
                  display: flex;
                  flex-direction: row;
                  margin-left: 25px;
                "
              >
                <v-select
                  :disabled="selectedData.length === 0"
                  label="Action"
                  style="position: relative; top: 15px; font-size: 12px"
                  v-model="actionValue"
                  :items="[
                    { id: 0, name: '' },
                    { id: 1, name: 'Delete' }
                  ]"
                  item-text="name"
                  item-value="id"
                  outlined
                  return-id
                  dense
                  @change="viewAction"
                ></v-select>
              </div>

              <div
                style="
                  width: 180px;
                  display: flex;
                  flex-direction: row;
                  margin-left: auto;
                "
              >
                <v-select
                  v-model="paramApi.company"
                  :items="dropdown.company"
                  @change="companyWatcher"
                  style="height: 40px"
                  label="Company"
                  item-text="name"
                  item-value="id"
                  return-id
                  dense
                  clearable
                  outlined
                ></v-select>
              </div>
              <div
                style="
                  margin-left: 10px;
                  width: 180px;
                  display: flex;
                  flex-direction: row;
                "
              >
                <v-autocomplete
                  v-model="paramApi.customer"
                  :items="dropdown.customer"
                  @change="customerWatcher"
                  label="Customer"
                  style="margin: 0; height: 40px"
                  item-text="name"
                  item-value="id"
                  return-id
                  outlined
                  dense
                  clearable
                  hide-details
                  hide-no-data
                ></v-autocomplete>
              </div>
            </v-toolbar>
            <v-toolbar flat style="height: 60px; border-radius: 10px 10px 0 0">
              <div style="width: 180px; display: flex; flex-direction: row">
                <v-select
                  v-model="paramApi.type"
                  :items="[
                    { id: 1, name: 'Internal' },
                    { id: 2, name: 'Eksternal' }
                  ]"
                  @change="typeWatcher"
                  style="height: 40px"
                  label="Tipe List Harga"
                  item-text="name"
                  item-value="id"
                  return-id
                  dense
                  outlined
                ></v-select>
              </div>
              <div
                style="
                  width: 180px;
                  display: flex;
                  flex-direction: row;
                  margin-left: auto;
                "
              >
                <v-select
                  v-model="paramApi.vehicle"
                  :items="dropdown.vehicle"
                  @change="vehicleWatcher"
                  style="height: 40px"
                  label="Kendaraan"
                  item-text="name"
                  item-value="id"
                  return-id
                  dense
                  clearable
                  outlined
                ></v-select>
              </div>
              <div
                style="
                  width: 180px;
                  display: flex;
                  flex-direction: row;
                  margin-left: 10px;
                "
              >
                <v-select
                  v-model="paramApi.zone"
                  :items="dropdown.zone"
                  @change="zoneWatcher"
                  style="height: 40px"
                  :label="paramApi.type === 2 ? 'Jarak' : 'Zona'"
                  item-text="name"
                  item-value="id"
                  return-object
                  dense
                  clearable
                  outlined
                ></v-select>
              </div>
            </v-toolbar>
          </div>
        </v-col>

        <v-col cols="12">
          <v-data-table
            v-model="selectedData"
            item-key="id"
            mobile-breakpoint="0"
            fixed-header
            height="60vh"
            :headers="headers"
            style="cursor: pointer"
            :items="result"
            :loading="loading"
            @click:row="rowClick"
            :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
            }"
            :server-items-length="totalData"
            @update:page="updatePage"
            @update:items-per-page="updateItemPerPage"
            show-select
            :single-select="true"
          >
            <template v-slot:[`item.price_list_type.name`]="{ item }">
              <div
                style="
                  font-size: 11px;
                  padding: 0;
                  display: flex;
                  flex-direction: row;
                "
              >
                {{ item.price_list_type.name }}
              </div>
            </template>
            <template v-slot:[`item.price_scheme`]="{ item }">
              <div
                style="
                  font-size: 11px;
                  padding: 0;
                  display: flex;
                  flex-direction: row;
                "
              >
                {{
                  item.price_scheme === 1
                    ? 'By Zone'
                    : item.price_scheme === 2
                    ? 'By KM'
                    : '-'
                }}
              </div>
            </template>
            <template v-slot:[`item.company_id`]="{ item }">
              <div
                style="
                  font-size: 11px;
                  padding: 0;
                  display: flex;
                  flex-direction: row;
                "
              >
                {{ companyName(item.company_id) }}
              </div>
            </template>
            <template v-slot:[`item.loading_from_company.name`]="{ item }">
              <div
                style="
                  font-size: 11px;
                  padding: 0;
                  display: flex;
                  flex-direction: row;
                "
              >
                {{
                  item.loading_from_company !== null
                    ? item.loading_from_company.name
                    : '-'
                }}
              </div>
            </template>
            <template v-slot:[`item.zone.name`]="{ item }">
              <div
                style="
                  font-size: 11px;
                  padding: 0;
                  display: flex;
                  flex-direction: row;
                "
              >
                {{ item.zone !== null ? item.zone.name : '-' }}
              </div>
            </template>
            <template v-slot:[`item.customer.name`]="{ item }">
              <div
                style="
                  font-size: 11px;
                  padding: 0;
                  display: flex;
                  flex-direction: row;
                "
              >
                {{ item.customer !== null ? item.customer.name : '-' }}
              </div>
            </template>
            <template v-slot:[`item.vehicle_type.name`]="{ item }">
              <div
                style="
                  font-size: 11px;
                  padding: 0;
                  display: flex;
                  flex-direction: row;
                "
              >
                {{ item.vehicle_type !== null ? item.vehicle_type.name : '-' }}
              </div>
            </template>
            <template v-slot:[`item.fixed_price`]="{ item }">
              <div
                style="
                  font-size: 11px;
                  padding: 0;
                  display: flex;
                  flex-direction: row;
                "
              >
                {{ priceFormat(item.fixed_price) }}
              </div>
            </template>
            <template v-slot:[`item.price_per_unit`]="{ item }">
              <div
                style="
                  font-size: 11px;
                  padding: 0;
                  display: flex;
                  flex-direction: row;
                "
              >
                {{ priceFormat(item.price_per_unit)
                }}{{
                  item.price_list_type.id === 2 ? `/${item.unit.name}` : ''
                }}
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="addDialog" full-screen persistent>
      <v-form
        v-if="form !== null"
        :disabled="loading || (form.act === 'update' && !isEdit)"
        lazy-validation
        ref="entryForm"
        @submit.prevent="submit"
        style="position: relative"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Formulir Tambah / Edit
          </v-card-title>

          <v-card-text
            class="d-flex justify-center flex-column"
            style="margin-top: 10px"
          >
            <v-row no-gutters>
              <v-col
                cols="12"
                style="
                  padding: 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-bottom: 10px;
                "
              >
                <div class="d-flex" style="padding: 0 10px; width: 100%">
                  <v-col
                    cols="12"
                    style="
                      padding: 0;
                      justify-content: flex-start;
                      align-items: center;
                      display: flex;
                    "
                  >
                    <div style="margin-left: auto" v-if="form.act === 'update'">
                      <a
                        v-if="!isEdit"
                        href=""
                        @click.prevent="isEdit = true"
                        style="font-size: 14px"
                        class="d-flex flex-row"
                      >
                        <v-icon small color="#1976d2" style="margin-right: 3px">
                          mdi-application-edit-outline
                        </v-icon>
                        Edit data
                      </a>
                      <p
                        v-else
                        @click.prevent="isEdit = false"
                        style="
                          font-size: 14px;
                          margin: 0;
                          color: red;
                          cursor: pointer;
                        "
                        class="d-flex flex-row"
                      >
                        Batal
                        <v-icon small color="red" style="margin-left: 3px">
                          mdi-close
                        </v-icon>
                      </p>
                    </div>
                  </v-col>
                </div>
              </v-col>

              <v-col cols="12" md="4" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Tipe List Harga
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-select
                      v-model="form.price_list_type"
                      :items="[
                        { id: 1, name: 'Internal' },
                        { id: 2, name: 'Eksternal' }
                      ]"
                      label="Pilih"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                    ></v-select>
                  </v-col>
                </div>
              </v-col>
              <v-col
                v-if="form.price_list_type === 2"
                cols="12"
                md="8"
                style="padding: 0"
              ></v-col>
              <v-col
                v-if="form.price_list_type === 1"
                cols="12"
                md="4"
                style="padding: 0"
              >
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Skema Harga
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-select
                      v-model="form.scheme"
                      :items="[
                        { id: 1, name: 'By Zone' },
                        { id: 2, name: 'By KM' }
                      ]"
                      label="Pilih"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                    ></v-select>
                  </v-col>
                </div>
              </v-col>
              <v-col
                v-if="form.price_list_type === 1"
                cols="12"
                md="4"
                style="padding: 0"
              ></v-col>
              <v-col cols="12" md="4" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Tipe Kendaraan
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-select
                      v-model="form.vehicle_type"
                      :items="dropdown.vehicle"
                      label="Pilih"
                      item-text="name"
                      item-value="id"
                      return-object
                      dense
                      outlined
                      clearable
                    ></v-select>
                  </v-col>
                </div>
              </v-col>
              <v-col
                v-if="form.price_list_type === 1"
                cols="12"
                md="8"
                style="padding: 0"
              ></v-col>
              <v-col cols="12" md="4" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Company
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-select
                      :items="dropdown.company"
                      v-model="form.company_id"
                      label="Pilih"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                      clearable
                    ></v-select>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="4" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Customer
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-select
                      v-model="form.customer"
                      :items="dropdown.customer"
                      label="Pilih"
                      item-text="name"
                      item-value="id"
                      return-object
                      dense
                      outlined
                      clearable
                    ></v-select>
                  </v-col>
                </div>
              </v-col>
              <v-col
                v-if="form.price_list_type === 1"
                cols="12"
                md="4"
                style="padding: 0"
              >
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Loading From Company
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-select
                      v-model="form.loading_from_company"
                      :items="dropdown.company"
                      label="Pilih"
                      item-text="name"
                      item-value="id"
                      return-object
                      dense
                      outlined
                      clearable
                    ></v-select>
                  </v-col>
                </div>
              </v-col>

              <v-col
                v-if="form.price_list_type === 2"
                cols="12"
                md="4"
                style="padding: 0"
              >
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Start Lokasi
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-autocomplete
                      v-model="form.loading_from_location"
                      :items="dropdown.loading_from_location"
                      :search-input.sync="dropdown.loadingFromLocationSearch"
                      label="Cari"
                      item-text="name"
                      item-value="id"
                      return-object
                      outlined
                      dense
                      clearable
                      hide-details
                      hide-no-data
                    ></v-autocomplete>
                  </v-col>
                </div>
              </v-col>
              <v-col
                v-if="form.price_list_type === 2"
                cols="12"
                md="4"
                style="padding: 0"
              >
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      End Lokasi
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-autocomplete
                      v-model="form.unloading_location"
                      :items="dropdown.unloading_location"
                      :search-input.sync="dropdown.unloadingLocationSearch"
                      label="Cari"
                      item-text="name"
                      item-value="id"
                      return-object
                      outlined
                      dense
                      clearable
                      hide-details
                      hide-no-data
                    ></v-autocomplete>
                  </v-col>
                </div>
              </v-col>

              <v-col
                v-if="form.price_list_type === 1"
                cols="12"
                md="4"
                style="padding: 0"
              >
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      {{
                        form.scheme === 1
                          ? 'Zona'
                          : form.scheme === 2
                          ? 'Jarak (km)'
                          : ''
                      }}
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-select
                      v-model="form.zone"
                      :items="dropdown.zone"
                      label="Pilih"
                      item-text="name"
                      item-value="id"
                      return-object
                      dense
                      outlined
                      clearable
                    ></v-select>
                  </v-col>
                </div>
              </v-col>
              <v-col
                v-if="form.scheme === 1 && form.price_list_type === 1"
                cols="12"
                md="4"
                style="padding: 0"
              >
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Produk
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-select
                      v-model="form.product"
                      :items="dropdown.product"
                      label="Pilih"
                      item-text="name"
                      item-value="id"
                      return-object
                      dense
                      outlined
                      clearable
                    ></v-select>
                  </v-col>
                </div>
              </v-col>
              <v-col
                v-if="form.price_list_type === 2"
                cols="12"
                md="4"
                style="padding: 0"
              ></v-col>
              <v-col
                v-if="form.scheme === 1"
                cols="12"
                md="4"
                style="padding: 0"
              >
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Unit
                    </p> </v-col
                  ><v-col cols="12" style="padding: 0">
                    <v-autocomplete
                      v-model="form.unit"
                      :items="dropdown.unit"
                      label="Pilih"
                      item-text="name"
                      item-value="id"
                      return-object
                      dense
                      outlined
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </div>
              </v-col>
              <v-col
                v-if="form.price_list_type === 2"
                cols="12"
                md="8"
                style="padding: 0"
              >
              </v-col>

              <v-col cols="12" md="4" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      {{
                        form.scheme === 1
                          ? 'Harga/Unit'
                          : form.scheme === 2
                          ? 'Harga/Km'
                          : ''
                      }}
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      v-model="form.price_per_unit"
                      dense
                      outlined
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="4" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      {{
                        form.scheme === 1
                          ? 'Harga Fix'
                          : form.scheme === 2
                          ? 'Harga Dasar'
                          : ''
                      }}
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field v-model="form.fixed_price" dense outlined />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="4" style="padding: 0"> </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              @click="closeDialog"
              color="error"
              text
              outlined
              type="reset"
              :loading="loading"
            >
              close
            </v-btn>
            <v-spacer />
            <v-btn
              color="primary"
              text
              outlined
              type="submit"
              :loading="loading"
              :disabled="form.act === 'update' && !isEdit"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
// import { mapGetters, mapActions } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'mspricelist',
  components: {},
  data: () => ({
    expedition: buildType.apiURL('expedition'),
    eSanqua: buildType.apiURL('esanqua'),
    proc: buildType.apiURL('proc'),
    build: process.env.VUE_APP_BUILD_TYPE,
    loading: false,
    totalData: 0,
    result: [],
    paramApi: {
      keyword: '',
      offset: 0,
      limit: 10,
      itemsPerPage: 10,
      page: 1,
      order_by: 'created_at',
      order_type: 'desc',
      type: 1,
      scheme: 1,
      customer: null,
      company: null,
      start_company: null,
      zone: null,
      product: null,
      vehicle: null
    },
    addDialog: false,
    form: null,
    isEdit: false,
    options: {},
    // headers: ,
    enableWatcher: true,
    selectedData: [],
    actionValue: 0,
    dropdown: {
      company: [],
      customer: [],
      vehicle: [],
      zone: [],
      product: [],
      unit: [],

      startLocation: [],
      startLocationSearch: null,
      startLocationSelect: null,

      endLocation: [],
      endLocationSearch: null,
      endLocationSelect: null,

      loading_from_location: [],
      loadingFromLocationSearch: null,
      loadingFromLocationSelect: null,

      unloading_location: [],
      unloadingLocationSearch: null,
      unloadingLocationSelect: null
    },
    rules: {
      companyRules: [],
      customerRules: [],
      vehicleTypeRules: [],
      loadingCompanyRules: [],
      loadingLocationRules: [],
      unLoadingLocationRules: [],
      zoneRules: [],
      priceKmRules: [],
      priceBasedRules: [],
      productRules: [],
      unitRules: []
    }
  }),
  async mounted() {
    this.getDataFromApi()
    this.initDropdown()
  },
  computed: {
    headers() {
      let arrHeaders = []
      if (this.paramApi.type === 1) {
        arrHeaders = [
          {
            text: 'Tipe',
            value: 'price_list_type.name',
            align: 'left',
            sortable: false
          },
          {
            text: 'Skema',
            value: 'price_scheme',
            align: 'left',
            sortable: false
          },
          {
            text: 'Company',
            value: 'company_id',
            align: 'left',
            sortable: false
          },
          {
            text: 'Start Company',
            value: 'loading_from_company.name',
            align: 'left',
            sortable: false
          },
          {
            text: 'Customer',
            value: 'customer.name',
            align: 'left',
            sortable: false
          },
          {
            text: 'Jenis Kendaraan',
            value: 'vehicle_type.name',
            align: 'left',
            sortable: false
          },
          {
            text: 'Zona',
            value: 'zone.name',
            align: 'left',
            sortable: false
          },
          {
            text: 'Harga Standar',
            value: 'fixed_price',
            align: 'left',
            sortable: false
          },
          {
            text: 'Harga/Km',
            value: 'price_per_unit',
            align: 'left',
            sortable: false
          }
        ]
      } else {
        arrHeaders = [
          {
            text: 'Tipe',
            value: 'price_list_type.name',
            align: 'left',
            sortable: false
          },
          {
            text: 'Company',
            value: 'company_id',
            align: 'left',
            sortable: false
          },
          {
            text: 'Customer',
            value: 'customer.name',
            align: 'left',
            sortable: false
          },
          {
            text: 'Lokasi Muat',
            value: 'loading_from_location.name',
            align: 'left',
            sortable: false
          },
          {
            text: 'Lokasi Bongkar',
            value: 'unloading_location.name',
            align: 'left',
            sortable: false
          },
          {
            text: 'Jenis Kendaraan',
            value: 'vehicle_type.name',
            align: 'left',
            sortable: false
          },
          {
            text: 'Harga/Unit',
            value: 'price_per_unit',
            align: 'left',
            sortable: false
          },
          {
            text: 'Harga Tetap',
            value: 'fixed_price',
            align: 'left',
            sortable: false
          }
        ]
      }

      return arrHeaders
    }
  },
  created() {},
  watch: {
    'dropdown.loadingFromLocationSearch'(val) {
      if (val !== '') {
        val &&
          val !== this.dropdown.loadingFromLocationSelect &&
          this.dropdownLoadingLocation(val)
      } else {
        this.dropdown.loadingFromLocationSelect = null
        this.dropdown.loading_from_location = []
      }
    },
    'dropdown.unloadingLocationSearch'(val) {
      if (val !== '') {
        val &&
          val !== this.dropdown.unloadingLocationSelect &&
          this.dropdownUnloadingLocation(val)
      } else {
        this.dropdown.unloadingLocationSelect = null
        this.dropdown.unloadingLocationSelect = []
      }
    }
  },
  methods: {
    viewAction(event) {
      switch (event) {
        case 1:
          this.deleteItem()
          break
      }
    },
    setAction() {
      this.actionValue = 0
      this.selectedData = []
    },
    refreshPage() {
      this.getDataFromApi()
      this.initDropdown()
    },
    async getDataFromApi() {
      this.result = []
      this.loading = true
      await this.initDataTable().then((data) => {
        setTimeout(() => {
          this.result = data.data
          this.totalData = data.total_record
          this.loading = false
        }, 1000)
      })
    },
    async initDataTable() {
      var url = `${this.expedition}price_list/list?&type=${
        this.paramApi.type
      }&offset=${this.paramApi.offset * this.paramApi.limit}&limit=${
        this.paramApi.limit
      }&order_type=${this.paramApi.order_type}&order_by=${
        this.paramApi.order_by
      }`
      // if (this.paramApi.company_id !== null) {
      const company = `&company=${
        this.paramApi.company !== null ? this.paramApi.company : ''
      }`
      const customer = `&customer=${
        this.paramApi.customer !== null ? this.paramApi.customer : ''
      }`
      const vehicle = `&vehicle_type=${
        this.paramApi.vehicle !== null ? this.paramApi.vehicle : ''
      }`
      const product = `&product=${
        this.paramApi.product !== null ? this.paramApi.product : ''
      }`
      const zone = `&zone=${
        this.paramApi.zone !== null ? this.paramApi.zone : ''
      }`
      url = url + company + customer + vehicle + product + zone

      return await new Promise((resolve) => {
        axios
          .get(url)
          .then((res) => {
            console.log(res)
            resolve(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },
    async initDropdown() {
      this.dropdownCompany()
      this.dropdownVehicle()
      this.dropdownCustomer()
      this.dropdownZone()
      this.dropdownProduct()
      this.dropdownUnit()
    },

    async zoneWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async typeWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async schemeWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async companyWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async customerWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async vehicleWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    // async priceTypeWatcher(v) {
    //   console.log(v)
    //   this.forn.price_list_type = v
    // },
    async dropdownLoadingLocation(v) {
      await axios
        .get(`${this.expedition}location/dropdown?keyword=${v}`)
        .then((res) => {
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.loading_from_location = res.data.data)
          }
          return (this.dropdown.loading_from_location = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.loading_from_location = [])
        })
    },
    async dropdownUnloadingLocation(v) {
      await axios
        .get(`${this.expedition}location/dropdown?keyword=${v}`)
        .then((res) => {
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.unloading_location = res.data.data)
          }
          return (this.dropdown.unloading_location = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.unloading_location = [])
        })
    },
    async dropdownCompany() {
      this.dropdown.company = []
      await axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then((res) => {
          if (res.data.status_code === '00') {
            const arr = res.data.data
            arr.unshift({ id: '', name: 'All' })
            return (this.dropdown.company = arr)
          }
          return (this.dropdown.company = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.company = [])
        })
    },
    async dropdownCustomer(v) {
      await axios
        .get(`${this.expedition}customer/dropdown`)
        .then((res) => {
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.customer = res.data.data)
          }
          return (this.dropdown.customer = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.customer = [])
        })
    },
    async dropdownVehicle(v) {
      await axios
        .get(`${this.expedition}vehicle_type/dropdown`)
        .then((res) => {
          console.log(res)
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.vehicle = res.data.data)
          }
          return (this.dropdown.vehicle = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.vehicle = [])
        })
    },
    async dropdownZone(v) {
      await axios
        .get(`${this.expedition}zone/dropdown`)
        .then((res) => {
          console.log(res)
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.zone = res.data.data)
          }
          return (this.dropdown.zone = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.zone = [])
        })
    },
    async dropdownProduct(v) {
      await axios
        .get(`${this.expedition}product/dropdown`)
        .then((res) => {
          console.log(res)
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.product = res.data.data)
          }
          return (this.dropdown.product = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.product = [])
        })
    },
    async dropdownUnit(v) {
      console.log('unit>>>>')
      await axios
        .get(`${this.proc}master/unit/drop_down?keyword=`)
        .then((res) => {
          console.log(res)
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.unit = res.data.data)
          }
          return (this.dropdown.unit = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.unit = [])
        })
    },
    rowClick(pItem) {
      this.setForm(pItem)

      setTimeout(() => {
        this.addDialog = true
      }, 500)
    },
    addPriceList() {
      this.setForm()
      setTimeout(() => {
        this.addDialog = true
      }, 500)
    },
    submit() {
      // this.rules = {
      //   companyRules: [v => !!v || 'Company is required'],
      //   customerRules: [v => !!v || 'Customer is required'],
      //   vehicleTypeRules: [v => !!v || 'Vehicle is required']
      // }
      const self = this
      setTimeout(function () {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      console.log(this.form)
      const form = {
        act: this.form.act,
        company_id: this.form.company_id,
        customer_id: this.form.customer.id,
        price_list_type: this.form.price_list_type,
        vehicle_type_id: this.form.vehicle_type.id,
        fixed_price: this.form.fixed_price,
        price_per_unit: this.form.price_per_unit,
        unit_id: this.form.unit !== null ? this.form.unit.id : null,
        unit_name: this.form.unit !== null ? this.form.unit.name : null
      }
      if (this.form.price_list_type === 1) {
        let loading_from_company_id = null
        let loading_from_company_name = null

        if (this.form.loading_from_company !== null) {
          loading_from_company_id = this.form.loading_from_company.id
          loading_from_company_name = this.form.loading_from_company.name
        }

        Object.assign(form, {
          price_scheme: this.form.scheme,
          zone_id: this.form.zone !== null ? this.form.zone.id : null,
          product_id: this.form.product !== null ? this.form.product.id : null,
          loading_from_company_id: loading_from_company_id,
          loading_from_company_name: loading_from_company_name
        })
      }

      if (this.form.price_list_type === 2) {
        Object.assign(form, {
          loading_from_location_id: this.form.loading_from_location.id,
          unloading_location_id: this.form.unloading_location.id,
          is_fixed_price: this.form.is_fixed_price
        })
      }

      if (this.form.act === 'update') {
        Object.assign(form, { id: this.form.id })
      }
      console.log(form)
      this.save(form)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.expedition}price_list/save`, form)
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            const self = this
            setTimeout(() => {
              self.closeDialog()
              this.getDataFromApi()
            }, this.showMsgDialog('success', res.data.status_msg, false))
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              return this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              return this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          return (this.loading = false)
        })
    },
    closeDialog() {
      this.addDialog = false
      this.$refs.entryForm.reset()
      this.form = null
    },
    async deleteItem() {
      this.showMsgDialog(
        'question',
        'Anda yakin ingin menghapus data ini sekarang',
        true
      ).then(async (res) => {
        if (res.isConfirmed) {
          await axios
            .delete(
              `${this.expedition}price_list/delete/${this.selectedData[0].id}`
            )
            .then((res) => {
              if (res.data.status_code === '00') {
                this.showMsgDialog('success', res.data.status_msg, false)
                this.setAction()
                this.getDataFromApi()
              } else {
                this.showMsgDialog('warning', res.data.status_msg, false)
              }
            })
            .catch((err) => {
              console.log(err)
              this.showMsgDialog('error', err, false)
            })
        } else {
          this.actionValue = 0
        }
      })
    },
    async updatePage(p) {
      this.paramApi.page = p
      this.paramApi.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramApi.limit = p
      this.paramApi.offset = 0
      await this.getDataFromApi()
    },
    setForm(xData) {
      console.log(xData)
      const x = xData !== undefined
      this.form = {
        act: x ? 'update' : 'add',
        company_id: x ? xData.company_id : null,
        customer: x ? xData.customer : null,
        scheme: x ? xData.price_scheme : 1,
        vehicle_type: x ? xData.vehicle_type : null,
        zone: x ? xData.zone : null,
        product: x ? xData.product : null,
        fixed_price: x ? xData.fixed_price : 0,
        price_per_unit: x ? xData.price_per_unit : 0,
        unit: x ? xData.unit : null,
        loading_from_company: x ? xData.loading_from_company : null,
        loading_from_location: x ? xData.loading_from_location : null,
        unloading_location: x ? xData.unloading_location : null,
        is_fixed_price: x ? xData.is_fixed_price : 0,
        price_list_type: x ? xData.price_list_type.id : 1
      }
      if (x) {
        Object.assign(this.form, { id: xData.id })
        if (xData.loading_from_location != null) {
          this.dropdownLoadingLocation(xData.loading_from_location.name)
        }

        if (xData.unloading_location != null) {
          this.dropdownUnloadingLocation(xData.unloading_location.name)
        }
      }
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    priceFormat(val) {
      if (val !== undefined) {
        if (val !== null) {
          if (val !== '') {
            return val.toString().replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',')
          }
        }
      }
      return ''
    },
    companyName(raw) {
      let name = ''
      switch (raw) {
        case 1:
          name = 'TMP'
          break
        case 2:
          name = 'IMP'
          break
        case 3:
          name = 'GIT'
          break
        case 4:
          name = 'KAJ'
          break
        case 5:
          name = 'WIM'
          break
        case 6:
          name = 'SMI'
          break
        case 7:
          name = 'THP'
          break
        case 8:
          name = 'GAI'
          break
        case 9:
          name = 'GPP'
          break
        case 10:
          name = 'BAWEN'
          break
        case 11:
          name = 'INO'
          break
        case 12:
          name = 'BPS'
          break
        case 13:
          name = 'BPC'
          break
      }
      return name
    }
  }
}
</script>
<style scoped lang="scss">
@media (max-width: 576px) {
}
@media (max-width: 320px) {
}
</style>
